"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createSdk = void 0;
require("whatwg-fetch");
const openapi_typescript_fetch_1 = require("openapi-typescript-fetch");
const createSdk = (config) => {
    const fetcher = openapi_typescript_fetch_1.Fetcher.for();
    fetcher.configure({
        init: {
            headers: {
                Accept: 'application/json',
            },
        },
        ...config
    });
    return {
        ...fetcher,
        loginWithEmailAndPassword: async (data) => {
            const loginWithEmailAndPassword = fetcher.path('/oauth2/token').method('post').create({
                hubspotFormData: true
            });
            const response = await loginWithEmailAndPassword({
                username: data.email,
                password: data.password,
                grant_type: 'password',
                hubspotFormData: data.hubspotFormData,
            });
            return response.data;
        },
        loginWithSSOToken: async () => {
            const loginsso = fetcher.path('/sso/login').method('post').create();
            const response = await loginsso({});
            return response.data;
        },
        registerUser: async (data) => {
            const registerUser = fetcher.path('/users').method('post').create();
            const response = await registerUser(data);
            return response.data.data;
        },
        forgotPassword: async (data) => {
            const forgotPassword = fetcher.path('/users/me/password/lost').method('post').create();
            const response = await forgotPassword(data);
            return response.data.data;
        },
        resetPassword: async (data) => {
            const resetPassword = fetcher.path('/users/me/password/reset').method('post').create();
            const response = await resetPassword(data);
            return response.data.data;
        },
        changePassword: async (data) => {
            const changePassword = fetcher.path('/users/me/password').method('put').create();
            const response = await changePassword(data);
            return response.data.data;
        },
        getUserData: async () => {
            const getUserData = fetcher.path('/users/me').method('get').create();
            const response = await getUserData({});
            return response.data.data;
        },
        verifyGoogleRegister: async (data) => {
            const verifyGoogleRegister = fetcher.path('/auth/google-verify').method('post').create();
            const response = await verifyGoogleRegister(data);
            return response.data;
        },
        listModules: async (data) => {
            const listModules = fetcher.path('/modules').method('get').create();
            const response = await listModules(data);
            return response.data.data;
        },
        getModule: async (id) => {
            const getModule = fetcher.path('/modules/{id}').method('get').create();
            const response = await getModule({ id });
            return response.data.data;
        },
        activateModule: async (moduleId, billingEntityId, connectorId, loggingEnabled) => {
            const activateModule = fetcher.path('/clients').method('post').create();
            const response = await activateModule({ moduleId, billingEntityId, connectorId, loggingEnabled });
            return response.data.data;
        },
        deactivateModule: async (clientId, cancellationReason, cancellationComment) => {
            const deactivateModule = fetcher.path('/clients/{id}').method('put').create();
            const response = await deactivateModule({ id: clientId, active: false, cancellationReason, cancellationComment });
            return response.data.data;
        },
        getCoupon: async (code) => {
            const getCoupon = fetcher.path('/coupons/{code}').method('get').create();
            const response = await getCoupon({ code });
            return response.data.data;
        },
        getBaseFeeInfo: async () => {
            const getBaseFeeInfo = fetcher.path('/checkout/baseFee').method('get').create();
            const response = await getBaseFeeInfo({});
            return response.data.data;
        },
        listSubscriptions: async () => {
            const listSubscriptions = fetcher.path('/clients').method('get').create();
            const response = await listSubscriptions({});
            return response.data.data;
        },
        updateSubscription: async (id, data) => {
            const updateSubscription = fetcher.path('/clients/{id}').method('put').create();
            const response = await updateSubscription({ id, ...data });
            return response.data.data;
        },
        cancelSubscription: async (id) => {
            const cancelSubscription = fetcher.path('/billingEntities/{id}/subscriptions').method('delete').create();
            const response = await cancelSubscription({ id });
            return response.data.data;
        },
        getBillingEntities: async () => {
            const getBillingEntities = fetcher.path('/billingEntities').method('get').create();
            const response = await getBillingEntities({});
            return response.data.data;
        },
        createBillingEntity: async (data) => {
            const createBillingEntity = fetcher.path('/billingEntities').method('post').create();
            const response = await createBillingEntity(data);
            return response.data.data;
        },
        getBillingEntity: async (id) => {
            const getBillingEntity = fetcher.path('/billingEntities/{id}').method('get').create();
            const response = await getBillingEntity({ id });
            return response.data.data;
        },
        updateBillingEntity: async (id, data) => {
            const updateBillingEntity = fetcher.path('/billingEntities/{id}').method('put').create();
            if (data.promoCode?.includes('*')) {
                data.promoCode = undefined;
            }
            const response = await updateBillingEntity({ id, ...data });
            return response.data.data;
        },
        createSetupIntent: async (id) => {
            const createSetupIntent = fetcher.path('/billingEntities/{id}/create-setup-intent').method('post').create();
            const response = await createSetupIntent({ id });
            return response.data.data;
        },
        getPaymentMethods: async (id) => {
            const getPaymentMethods = fetcher.path('/billingEntities/{id}/payment-methods').method('get').create();
            const response = await getPaymentMethods({ id });
            return response.data.data;
        },
        getBalance: async (id) => {
            const getBalance = fetcher.path('/billingEntities/{id}/balance').method('get').create();
            const response = await getBalance({ id });
            return response.data.data;
        },
        getInvoices: async (id, startingAfter, limit) => {
            const getInvoices = fetcher.path('/billingEntities/{id}/invoices').method('get').create();
            const response = await getInvoices({ id, startingAfter, limit });
            return response.data.data;
        },
        getDiscount: async (id) => {
            const GetDiscount = fetcher.path('/billingEntities/{id}/discount').method('get').create();
            const response = await GetDiscount({ id });
            return response.data.data;
        },
        getSubscriptionItems: async (id) => {
            const getSubscriptionItems = fetcher.path('/billingEntities/{id}/subscriptions').method('get').create();
            const response = await getSubscriptionItems({ id });
            return response.data.data;
        },
        getEntityDailyUsage: async (id) => {
            const getEntityDailyUsage = fetcher.path('/billingEntities/{id}/daily-usage').method('get').create();
            const response = await getEntityDailyUsage({ id });
            return response.data.data;
        },
        getIncompleteEntities: async () => {
            const getIncompleteEntities = fetcher.path('/billingEntities/incomplete').method('get').create();
            const response = await getIncompleteEntities({});
            return response.data.data;
        }, createTeamInvite: async (data) => {
            const createTeamInvite = fetcher.path('/teams/me/invites').method('post').create();
            const response = await createTeamInvite(data);
            return response.data.data;
        },
        listTeamInvites: async () => {
            const listTeamInvites = fetcher.path('/teams/me/invites').method('get').create();
            const response = await listTeamInvites({});
            return response.data.data;
        },
        listTeamMembers: async () => {
            const listTeamMembers = fetcher.path('/teams/me/members').method('get').create();
            const response = await listTeamMembers({});
            return response.data.data;
        },
        deleteTeamInvite: async ({ id }) => {
            const deleteTeamInvite = fetcher.path('/teams/me/invites/{id}').method('delete').create();
            const response = await deleteTeamInvite({ id });
            return response.data.data;
        },
        setTeamCurrency: async (currency) => {
            const setTeamCurrency = fetcher.path('/teams/me/currency').method('put').create();
            const response = await setTeamCurrency({ currency });
            return response.data.data;
        },
        getTeamInviteDetails: async ({ token, }) => {
            const getTeamInviteDetails = fetcher.path('/teams/invites').method('get').create();
            const response = await getTeamInviteDetails({ token });
            return response.data.data;
        },
        createConnector: async (data) => {
            const createConnector = fetcher.path('/connectors').method('post').create();
            const response = await createConnector(data);
            return response.data.data;
        },
        updateConnector: async (id, data) => {
            const updateConnector = fetcher.path('/connectors/{id}').method('put').create();
            const response = await updateConnector({ id, ...data });
            return response.data.data;
        },
        deleteConnector: async (id) => {
            const deleteConnector = fetcher.path('/connectors/{id}').method('delete').create();
            const response = await deleteConnector({ id });
            return response.data.data;
        },
        listConnectors: async () => {
            const listConnectors = fetcher.path('/connectors').method('get').create();
            const response = await listConnectors({});
            return response.data.data;
        },
        getZendeskConnectorUrl: async (data) => {
            const getZendeskConnectorUrl = fetcher.path('/zendesk/connectors/url').method('post').create();
            const response = await getZendeskConnectorUrl(data);
            return response.data.data;
        },
        getSharepointConnectorUrl: async (data) => {
            const getSharepointConnectorUrl = fetcher.path('/microsoft/sharepoint/connectors/url').method('post').create();
            const response = await getSharepointConnectorUrl(data);
            return response.data.data;
        },
        getGoogleConnectorUrl: async (data) => {
            const getGoogleConnectorUrl = fetcher.path('/google/connectors/url').method('post').create();
            const response = await getGoogleConnectorUrl(data);
            return response.data.data;
        },
        createFreshdeskConnector: async (data) => {
            const createConnector = fetcher.path('/freshdesk/connectors').method('post').create();
            const response = await createConnector(data);
            return response.data.data;
        },
        createShopifyConnector: async (data) => {
            const createConnector = fetcher.path('/shopify/connectors').method('post').create();
            const response = await createConnector(data);
            return response.data.data;
        },
        createShopwareConnector: async (data) => {
            const createConnector = fetcher.path('/shopware/v5/connectors').method('post').create();
            const response = await createConnector(data);
            return response.data.data;
        },
        getShopifyConnectorUrl: async (name, subdomain, keySet) => {
            const getShopifyConnectorUrl = fetcher.path('/shopify/connectors/url').method('post').create();
            const response = await getShopifyConnectorUrl({ name, subdomain, keySet });
            return response.data.data;
        },
        finalizeShopifyConnector: async (state) => {
            const finalizeShopifyConnector = fetcher.path('/shopify/connectors/finalize-installed').method('post').create();
            const response = await finalizeShopifyConnector({ state });
            return response.data.data;
        },
        getJiraConnectorUrl: async (data) => {
            const getJiraConnectorUrl = fetcher.path('/jira/connectors/url').method('post').create();
            const response = await getJiraConnectorUrl(data);
            return response.data.data;
        },
        createPrepaymentRequest: async (id, data) => {
            const createPrepaymentRequest = fetcher.path('/billingEntities/{id}/prepayment-request').method('post').create();
            const response = await createPrepaymentRequest({ id, ...data });
            return response.data.data;
        },
        getTrialInfo: async ({ filters }) => {
            const getTrialInfo = fetcher.path('/billingEntities/trial-info').method('get').create();
            const response = await getTrialInfo({ filters });
            return response.data.data;
        },
        receiveFeedback: async (data) => {
            const receiveFeedback = fetcher.path('/feedback').method('post').create();
            const response = await receiveFeedback(data);
            return response.data.data;
        }
    };
};
exports.createSdk = createSdk;
