import { ProtectedRoute } from '@/components/ProtectedRoute/ProtectedRoute'
import { lazyImport } from '@/utils/lazyImport'

const { MemberLayout } = lazyImport(() => import('@/components/MemberLayout'), 'MemberLayout')
const { Landing } = lazyImport(() => import('@/features/misc'), 'Landing')
const { AllModules } = lazyImport(() => import('@/features/modules'), 'AllModules')
const { Invoices } = lazyImport(() => import('@/features/payment'), 'Invoices')
const { BillingEntities } = lazyImport(() => import('@/features/payment'), 'BillingEntities')
const { Subscriptions } = lazyImport(() => import('@/features/payment'), 'Subscriptions')
const { Profile } = lazyImport(() => import('@/features/settings'), 'Profile')
const { Connectors } = lazyImport(() => import('@/features/connectors'), 'Connectors')
const { LandingShopify } = lazyImport(() => import('@/features/connectors'), 'LandingShopify')
const { Team } = lazyImport(() => import('@/features/settings'), 'Team')

export const enum ValidMemberRoute {
  Home = '/',
  Triggers = '/triggers',
  Pages = '/pages',
  Documentation = '/documentation',
  Subscriptions = '/payment/subscriptions',
  Invoices = '/payment/invoices',
  BillingEntities = '/payment/entities',
  AllModules = '/modules/all',
  ProfileSettings = '/settings/profile',
  TeamSettings = '/settings/team',
  Connectors = '/modules/connectors',
  ShopifyLanding = '/modules/connectors/shopify',
}

export type RouteProp = {
  title: string
  navTitle: string
  navCategory?: string
  hideInNav?: boolean
  subNavList?: ValidMemberRoute[]
  returnRoute?: ValidMemberRoute
  returnTitle?: string
  element: JSX.Element
}

const modulesSubnav: ValidMemberRoute[] = [ValidMemberRoute.AllModules, ValidMemberRoute.Connectors]
const paymentSubNav: ValidMemberRoute[] = [
  ValidMemberRoute.Subscriptions,
  ValidMemberRoute.Invoices,
  ValidMemberRoute.BillingEntities,
]
const settingsSubNav: ValidMemberRoute[] = [ValidMemberRoute.ProfileSettings, ValidMemberRoute.TeamSettings]

export const MemberRoutes: Record<ValidMemberRoute, RouteProp> = {
  [ValidMemberRoute.Home]: {
    title: 'header.title.home',
    navTitle: 'nav.home',
    hideInNav: true,
    element: <Landing />,
  },
  [ValidMemberRoute.Triggers]: {
    title: 'header.title.triggers',
    navTitle: 'nav.triggers',
    hideInNav: true,
    element: <Landing />,
  },
  [ValidMemberRoute.Pages]: {
    title: 'header.title.pages',
    navTitle: 'nav.pages',
    hideInNav: true,
    element: <Landing />,
  },
  [ValidMemberRoute.Documentation]: {
    title: 'header.title.documentation',
    navTitle: 'nav.documentation',
    hideInNav: true,
    element: <Landing />,
  },
  [ValidMemberRoute.AllModules]: {
    title: 'header.title.allModules',
    navTitle: 'nav.modules',
    navCategory: 'nav.modules',
    //hideInNav: true,
    //returnRoute: ValidMemberRoute.Subscriptions,
    //returnTitle: 'header.return.myModules',
    subNavList: modulesSubnav,
    element: <AllModules />,
  },
  [ValidMemberRoute.Connectors]: {
    title: 'header.title.connectors',
    navTitle: 'nav.connectors',
    navCategory: 'nav.modules',
    subNavList: modulesSubnav,
    element: <Connectors />,
  },
  [ValidMemberRoute.ShopifyLanding]: {
    title: 'header.title.connectors',
    navTitle: 'nav.connectors',
    navCategory: 'nav.modules',
    subNavList: modulesSubnav,
    element: <LandingShopify />,
  },
  [ValidMemberRoute.Subscriptions]: {
    title: 'header.title.subscriptions',
    navTitle: 'nav.subscriptions',
    navCategory: 'nav.payments',
    subNavList: paymentSubNav,
    element: <Subscriptions />,
  },
  [ValidMemberRoute.Invoices]: {
    title: 'header.title.invoices',
    navTitle: 'nav.invoices',
    navCategory: 'nav.payments',
    //hideInNav: true,
    subNavList: paymentSubNav,
    element: <Invoices />,
  },
  [ValidMemberRoute.BillingEntities]: {
    title: 'header.title.billingEntities',
    navTitle: 'nav.billingEntities',
    navCategory: 'nav.payments',
    //hideInNav: true,
    subNavList: paymentSubNav,
    element: <BillingEntities />,
  },
  [ValidMemberRoute.ProfileSettings]: {
    title: 'header.title.profile',
    navTitle: 'nav.profile',
    //navCategory: 'nav.settings',
    hideInNav: true,
    subNavList: settingsSubNav,
    element: <Profile />,
  },
  [ValidMemberRoute.TeamSettings]: {
    title: 'header.title.team',
    navTitle: 'nav.team',
    //navCategory: 'nav.settings',
    hideInNav: true,
    subNavList: settingsSubNav,
    element: <Team />,
  },
}

const formattedMemberRoutes = Object.keys(MemberRoutes).map((route) => ({
  path: route,
  element: (
    <ProtectedRoute>
      <MemberLayout>{MemberRoutes[route as ValidMemberRoute].element}</MemberLayout>
    </ProtectedRoute>
  ),
}))

export const protectedRoutes = [...formattedMemberRoutes]
