import { useQuery } from '@tanstack/react-query'

import { knotsApiSdk } from '@/lib/fetcher'
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query'

export const useTrialInfo = (
  params: { moduleName?: string; config?: QueryConfig<typeof knotsApiSdk.getTrialInfo> } = {}
) => {
  const { config, moduleName } = params
  return useQuery<ExtractFnReturnType<typeof knotsApiSdk.getTrialInfo>>({
    queryKey: ['trialInfo', moduleName],
    queryFn: () => knotsApiSdk.getTrialInfo({ filters: { module: { name: moduleName } } }),
    ...config,
  })
}
