import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { Button } from '@/components/Elements'

export const AlreadyRegistered = () => {
  const { t } = useTranslation()
  const location = useLocation()

  return (
    <>
      <div className="text-center">
        <h2>{t('auth.alreadyregistered.title')}</h2>
        <div>{t('auth.alreadyregistered.text.alreadyRegistered')}</div>
        <Button className="w-100" href={`/auth/login${location.search?.length ? location.search : ''}`}>
          {t('auth.alreadyregistered.button.login')}
        </Button>
      </div>
    </>
  )
}
