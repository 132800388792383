import { PublicLayout } from '@/components/PublicLayout'
import { Feedback } from '@/features/feedback'
import { lazyImport } from '@/utils/lazyImport'

const { AuthRoutes } = lazyImport(() => import('@/features/auth'), 'AuthRoutes')

export const publicRoutes = [
  {
    path: '/auth/*',
    element: <AuthRoutes />,
  },
  {
    path: '/cookie-policy',
    element: (
      <PublicLayout title="header.title.cookie">
        <div id="cookiefirst-policy-page" />
        <div>
          This cookie policy has been created and updated by <a href="https://cookiefirst.com">CookieFirst.com</a>.
        </div>
      </PublicLayout>
    ),
  },
  {
    path: '/feedback',
    element: (
      <PublicLayout title="header.title.feedback">
        <Feedback />
      </PublicLayout>
    ),
  },
]
