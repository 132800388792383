import { useRoutes } from 'react-router-dom'

//import { useAuth } from '@/lib/auth'

import { protectedRoutes } from './protected'
import { publicRoutes } from './public'

export const AppRoutes = () => {
  const element = useRoutes([...protectedRoutes, ...publicRoutes])

  return <>{element}</>
}
