import { Container, Nav } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { BUILD_NUMBER } from '@/config'

export const Footer = () => {
  const { t } = useTranslation()

  return (
    <footer className="position mt-auto pb-1">
      <Container fluid="lg">
        <Nav>
          <Nav.Item>
            <Nav.Link href={t('footer.text.imprintUrl')} target="_blank">
              {t('footer.text.imprint')}
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link href="/cookie-policy" target="_blank">
              {t('footer.text.cookiePolicy')}
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link href={t('footer.text.privacyPolicyUrl')} target="_blank">
              {t('footer.text.privacyPolicy')}
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link href={t('footer.text.termsUrl')} target="_blank">
              {t('footer.text.terms')}
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link href={t('footer.text.dpaUrl')} target="_blank">
              {t('footer.text.dpa')}
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className="pe-none">
            <Nav.Link>v. {BUILD_NUMBER}</Nav.Link>
          </Nav.Item>
        </Nav>
      </Container>
    </footer>
  )
}
