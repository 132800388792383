import { QueryClientProvider } from '@tanstack/react-query'
import { ToastContainer } from 'react-toastify'

import { AppProvider } from '@/providers/app'
import { AuthProvider } from '@/providers/auth'
import { AppRoutes } from '@/routes'

import { queryClient } from './lib/react-query'

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <AppProvider>
          <ToastContainer />
          <AppRoutes />
        </AppProvider>
      </AuthProvider>
    </QueryClientProvider>
  )
}

export default App
