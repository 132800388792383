import { AuthForm, AuthFormState } from '@knots/shared-frontend'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import { defaultUtmSource } from '@/config'
import { knotsApiSdk } from '@/lib/fetcher'
import { toastSuccess } from '@/lib/toast'
import { showError } from '@/utils/error'

import { AuthLayout } from '../components/AuthLayout'
import { NoAccount } from '../components/NoAccount'

export const ForgotPassword = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()

  return (
    <AuthLayout
      title={t('auth.signin.title')}
      left={
        <AuthForm
          knotsApiSdk={knotsApiSdk}
          state={AuthFormState.Forgot}
          onError={showError}
          switchToLogin={() => {
            navigate({ pathname: '/auth/login', search: location.search })
          }}
          onResetSent={() => {
            toastSuccess(t('auth.forgot.alert.requestSuccess'))
          }}
          location={location as any}
          source={defaultUtmSource}
        />
      }
      right={<NoAccount />}
    />
  )
}
