import { AuthForm, AuthFormState } from '@knots/shared-frontend'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import { defaultUtmSource } from '@/config'
import { knotsCFApiSdk } from '@/lib/fetcher'
import { toastSuccess } from '@/lib/toast'
import { useAuth } from '@/providers/auth'
import { showError } from '@/utils/error'

import { AlreadyRegistered } from '../components/AlreadyRegistered'
import { AuthLayout } from '../components/AuthLayout'

export const Register = () => {
  const { t } = useTranslation()
  const { login } = useAuth()
  const location = useLocation()
  const navigate = useNavigate()
  const { redirectUrl, inviteToken, oauthEmail } = React.useMemo(() => {
    const urlParams = new URLSearchParams(location.search)

    return {
      redirectUrl: urlParams.get('login_redirect') ?? undefined,
      inviteToken: urlParams.get('invite_token') ?? undefined,
      oauthEmail: urlParams.get('oauth_email') ?? undefined,
    }
  }, [location.search])

  const redirect = (url: string) => {
    if (url.startsWith('http')) {
      window.location.href = url
    } else {
      navigate(url)
    }
  }

  return (
    <AuthLayout
      title={t('auth.register.title')}
      left={
        <AuthForm
          knotsApiSdk={knotsCFApiSdk}
          state={AuthFormState.Register}
          onError={showError}
          onLoginSuccess={({ access_token, stayLoggedIn }) => {
            login(access_token, stayLoggedIn)
            toastSuccess(t('auth.register.alert.success'))
            if (redirectUrl) {
              redirect(decodeURIComponent(redirectUrl))
            }
          }}
          switchToLogin={() => {
            navigate({ pathname: '/auth/login', search: location.search })
          }}
          email={oauthEmail}
          inviteToken={inviteToken}
          location={location as any}
          source={defaultUtmSource}
        />
      }
      right={<AlreadyRegistered />}
    />
  )
}
