import { ReactComponent as IconChevronDown } from '@/assets/icons/chevron-down.svg'

import { Link } from '../Link'

export const DetailsToggle: React.FC<{ label: string; active: boolean; onChange: (_: boolean) => void }> = ({
  label,
  active,
  onChange,
}) => {
  return (
    <Link
      className={`details-toggle text-nowrap ${active ? 'details-toggle--active' : ''}`}
      to=""
      onClick={(e) => {
        e.preventDefault()
        onChange(!active)
      }}
      role="switch"
      aria-checked={active}
    >
      {label}
      <IconChevronDown />
    </Link>
  )
}
