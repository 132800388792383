import { i18n } from '@knots/shared-frontend'

import { toastError } from '@/lib/toast'
import { components } from '@/types/apiSchema'

export type ApiError = components['schemas']['Error']

export const getIntlMessage = (error: unknown) => {
  if (typeof error === 'string') {
    return i18n.t(error)
  } else if (typeof error === 'object' && error !== null && 'getActualType' in error) {
    const _error = (error as { getActualType: () => { data: ApiError } }).getActualType().data

    if (typeof _error === 'object' && _error !== null && ('intlMessage' in _error || 'message' in _error)) {
      if ('intlMessage' in _error && _error.intlMessage) {
        if (!_error.intlMessage.startsWith('errors.validation.') || i18n.exists(_error.intlMessage)) {
          return i18n.t(_error.intlMessage)
        }

        // fallback to support both old and new validation intlMessage
        // errors.validation.search[0].pattern.notType => errors.validation.notType
        const parts = _error.intlMessage.split('.')

        return i18n.t([parts[0], parts[1], parts[parts.length - 1]].join('.'))
      } else if ('message' in _error && _error.message) {
        return i18n.t(_error.message)
      }
    }
  } else if (error && typeof error === 'object' && 'message' in error) {
    return i18n.t(error.message as string)
  }

  return i18n.t('errors.somethingWrong')
}

export const showError = (error: unknown) => {
  const message = getIntlMessage(error)
  toastError(message)
  console.error(message)
}
