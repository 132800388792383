import { AuthForm, AuthFormState } from '@knots/shared-frontend'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import { defaultUtmSource } from '@/config'
import { knotsApiSdk } from '@/lib/fetcher'
import { useAuth } from '@/providers/auth'
import { showError } from '@/utils/error'

import { AuthLayout } from '../components/AuthLayout'
import { NoAccount } from '../components/NoAccount'

export const Login = () => {
  const { t } = useTranslation()
  const { login } = useAuth()
  const navigate = useNavigate()
  const location = useLocation()

  return (
    <AuthLayout
      title={t('auth.signin.title')}
      left={
        <AuthForm
          knotsApiSdk={knotsApiSdk}
          state={AuthFormState.Login}
          onError={showError}
          onLoginSuccess={({ access_token, stayLoggedIn }) => {
            login(access_token, stayLoggedIn)
          }}
          switchToForgotPassword={() => navigate({ pathname: '/auth/forgot-password', search: location.search })}
          location={location as any}
          source={defaultUtmSource}
        />
      }
      right={<NoAccount />}
    />
  )
}
