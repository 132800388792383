import { toast, ToastOptions } from 'react-toastify'

const toastConfig: ToastOptions = {
  position: 'top-center',
  autoClose: 4000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
}

export const toastError = (message: string, options: Partial<ToastOptions> = {}) => {
  toast.error(message, { ...toastConfig, ...options })
}

export const toastSuccess = (message: string, options: Partial<ToastOptions> = {}) => {
  toast.success(message, { ...toastConfig, ...options })
}
