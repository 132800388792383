/* eslint-disable-next-line @typescript-eslint/no-unused-vars */
import { i18n } from '@knots/shared-frontend'
import * as React from 'react'
import { createRoot } from 'react-dom/client'

import App from './App'
import './index.scss'

const container = document.getElementById('root')
if (!container) throw 'No root container'
const root = createRoot(container)

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)
