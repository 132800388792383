import { createSdk } from '@knots/api-sdk'
import type { Middleware } from 'openapi-typescript-fetch'

import { API_URL, CF_API_URL } from '@/config'
import storage from '@/utils/storage'

const auth: Middleware = async (url, init, next) => {
  const token = storage.getToken()

  if (token) {
    init.headers.append('authorization', `Bearer ${token}`)
  }

  return await next(url, init)
}

const authFailHandler: Middleware = async (url, init, next) => {
  try {
    const response = await next(url, init)

    if (response.status === 403 || response.status === 401) {
      storage.clearToken()
      window.location.assign(window.location.origin as unknown as string)
    }

    return response
  } catch (error: any) {
    if (error.status === 403 || error.status === 401) {
      storage.clearToken()
      window.location.assign(window.location.origin as unknown as string)
      throw new Error('Bad authentication token')
    } else {
      throw error
    }
  }
}

export const knotsApiSdk = createSdk({
  baseUrl: API_URL,
  use: [auth, authFailHandler],
})

export const knotsCFApiSdk = createSdk({
  baseUrl: CF_API_URL,
})
