import React from 'react'

export const CustomDropdownToggle = React.forwardRef<HTMLAnchorElement, React.ComponentProps<'a'>>(
  ({ children, onClick }, ref) => (
    <a
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault()
        if (onClick) onClick(e)
      }}
    >
      {children}
    </a>
  )
)
CustomDropdownToggle.displayName = 'CustomDropdownToggle'
