import React from 'react'
import { Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { Button } from '@/components/Elements'
import { knotsApiSdk } from '@/lib/fetcher'

export const Feedback = () => {
  const { t } = useTranslation()

  const [token, setToken] = React.useState<string>()
  const [cancellationReason, setCancellationReason] = React.useState<string>()
  const [cancellationComment, setCancellationComment] = React.useState('')
  const [sent, setSent] = React.useState(false)

  React.useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const token = params.get('token') || undefined
    const reason = params.get('reason') || undefined
    setToken(token)
    setCancellationReason(reason)

    if (!token || !reason) return

    knotsApiSdk.receiveFeedback({
      token,
      reason: reason,
      comment: '',
    })
  }, [])

  return (
    <div>
      <div className="d-flex flex-column align-items-center text-center my-4">
        <h2>{t('feedback.cancellationThanks')}</h2>
        {token && !sent && (
          <>
            <h4 className="mt-4">{t('feedback.commentHere')}</h4>
            <Form.Control
              className="my-3"
              as="textarea"
              placeholder={t('feedback.commentPlaceholder')}
              style={{ height: '100px', width: '400px' }}
              value={cancellationComment}
              onChange={(e) => setCancellationComment(e.target.value)}
            />
            <Button
              onClick={async () => {
                knotsApiSdk.receiveFeedback({
                  token,
                  reason: cancellationReason || 'other',
                  comment: cancellationComment,
                })
                setSent(true)
              }}
            >
              {t('feedback.sendComment')}
            </Button>
          </>
        )}
        {sent && <h4 className="mt-4">{t('feedback.commentSent')}</h4>}
      </div>
    </div>
  )
}
