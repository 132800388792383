import * as React from 'react'
import BtButton, { ButtonProps as BtButtonProps } from 'react-bootstrap/Button'
import { useTranslation } from 'react-i18next'
import { LinkContainer } from 'react-router-bootstrap'

import { usePreserveSearch } from '@/hooks/usePreserveSearch'
import { AnalyticEvent, fireAnalyticEvent } from '@/utils/analytics'

export type ButtonProps = BtButtonProps & {
  isLoading?: boolean
  loadingLabel?: string
  clickEvent?: AnalyticEvent
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      type = 'button',
      isLoading = false,
      loadingLabel = 'default.button.loadingLabel',
      clickEvent = undefined,
      ...props
    },
    ref
  ) => {
    const { t } = useTranslation()
    const preserveSearch = usePreserveSearch()

    const { href = false, target = undefined, disabled = false, ...otherProps } = props

    const onClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
      if (clickEvent) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        fireAnalyticEvent(clickEvent, event.target.dataset)
      }

      otherProps.onClick && otherProps.onClick(event)
    }

    if (href && target !== '_blank') {
      let to

      if (href.includes('?')) {
        to = {
          pathname: href.split('?')[0],
          search: href.split('?')[1],
        }
      } else {
        to = href
      }

      // We need linkcontainer to work with the react router
      return (
        <LinkContainer to={preserveSearch(to, ['activation_redirect', /^utm_.+/])}>
          <BtButton
            ref={ref}
            type={type}
            {...otherProps}
            disabled={isLoading ? true : disabled}
            data-name={name}
            onClick={onClick}
          >
            {isLoading ? t(loadingLabel) : props.children}
          </BtButton>
        </LinkContainer>
      )
    } else {
      return (
        <BtButton
          ref={ref}
          type={type}
          {...props}
          disabled={isLoading ? true : disabled}
          data-name={name}
          onClick={onClick}
        >
          {isLoading ? t(loadingLabel) : props.children}
        </BtButton>
      )
    }
  }
)

Button.displayName = 'Button'
