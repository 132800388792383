export enum AnalyticEvent {
  ClickActivateModule = 'click_activate_module',
  ClickInstallApp = 'click_install_app',
  ClickCheckoutConfirm = 'click_checkout_confirm',
}

export const fireAnalyticEvent = (name: AnalyticEvent, data: Record<string, any>) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window.dataLayer.push({ ...data, event: name })
}
