import { PropsWithChildren } from 'react'
import { Navigate, useLocation } from 'react-router-dom'

import { useAuth } from '@/providers/auth'

export const ProtectedRoute: React.FC<PropsWithChildren> = ({ children }) => {
  const { user, initialized } = useAuth()
  const location = useLocation()

  if (!initialized) return null
  else if (user) {
    return <>{children}</>
  } else {
    const currentSearchParams = new URLSearchParams(location.search)
    const newSearchParams = new URLSearchParams()
    const redirectSearchParams = new URLSearchParams()

    currentSearchParams.forEach((value, key) => {
      if (key.toLowerCase().startsWith('utm_')) {
        newSearchParams.set(key.toLowerCase(), value)
      }
      redirectSearchParams.set(key, value)
    })

    if (redirectSearchParams.size > 0) {
      newSearchParams.set(
        'login_redirect',
        encodeURIComponent(`${location.pathname}?${redirectSearchParams.toString()}`)
      )
    }

    return (
      <Navigate
        to={{
          pathname: '/auth/login',
          search: newSearchParams.toString(),
        }}
      />
    )
  }
}
