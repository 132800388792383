import { AuthForm, AuthFormState } from '@knots/shared-frontend'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { defaultUtmSource } from '@/config'
import { knotsApiSdk } from '@/lib/fetcher'
import { toastSuccess } from '@/lib/toast'
import { showError } from '@/utils/error'

import { AuthLayout } from '../components/AuthLayout'
import { NoAccount } from '../components/NoAccount'

export const ResetPassword = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <AuthLayout
      title={t('auth.forgot.title')}
      left={
        <AuthForm
          knotsApiSdk={knotsApiSdk}
          state={AuthFormState.Reset}
          onError={showError}
          switchToLogin={() => {
            navigate({ pathname: '/auth/login', search: location.search })
          }}
          onResetSuccess={() => {
            toastSuccess(t('auth.reset.alert.success'))
            setTimeout(() => navigate({ pathname: '/auth/login', search: location.search }), 3000)
          }}
          location={location as any}
          navigate={navigate}
          source={defaultUtmSource}
        />
      }
      right={<NoAccount />}
    />
  )
}
