import { Link as RouterLink, LinkProps } from 'react-router-dom'

import { usePreserveSearch } from '@/hooks/usePreserveSearch'

export const Link = ({ children, to, ...props }: LinkProps) => {
  const preserveSearch = usePreserveSearch()
  return (
    <RouterLink {...props} to={preserveSearch(to, ['activation_redirect', /^utm_.+/])}>
      {children}
    </RouterLink>
  )
}
