import { copyTextToClipboard } from '@knots/shared-frontend'
import React, { useState } from 'react'

import { ReactComponent as CheckIcon } from '@/assets/icons/check-circle.svg'
import { ReactComponent as CopyIcon } from '@/assets/icons/copy.svg'

import { Button, ButtonProps } from './Button'

export type CopyButtonProps = {
  value: string
} & ButtonProps

export const CopyButton = ({ value, ...props }: CopyButtonProps) => {
  const [isActive, setIsActive] = useState(false)
  const handleClick = async () => {
    setIsActive(true)
    await copyTextToClipboard(value)

    setTimeout(() => {
      setIsActive(false)
    }, 2000)
  }

  return (
    <Button {...props} onClick={handleClick} variant="outline-info" size="sm">
      {isActive ? <CheckIcon /> : <CopyIcon />}
    </Button>
  )
}
