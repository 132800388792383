import * as React from 'react'
import { Container } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { ReactComponent as BackgroundTexture } from '@/assets/background-texture.svg'
import { Footer } from '@/components/Footer'
import { Head } from '@/components/Head'

import { MyNavbar } from '../MyNavbar'

type PublicLayoutProps = {
  title: string
  children: React.ReactNode
}

export const PublicLayout = ({ children, title }: PublicLayoutProps) => {
  const { t } = useTranslation()

  return (
    <>
      <Head title={t(title)} />
      <MyNavbar />

      <div className="member-header">
        <BackgroundTexture className="member-header__bg member-header__bg--left" />
        <BackgroundTexture className="member-header__bg member-header__bg--right" />
        <Container className="member-header__container" fluid="lg">
          <h2 className="font-weight-extrabold member-header__title">{t(title)}</h2>
        </Container>
      </div>
      <Container className="layout__container" fluid="lg">
        {children}
      </Container>
      <Footer />
    </>
  )
}
