import { useQuery } from '@tanstack/react-query'

import { knotsApiSdk } from '@/lib/fetcher'
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query'

export const useUserData = (params: { config?: QueryConfig<typeof knotsApiSdk.getUserData> } = {}) => {
  const { config = {} } = params
  return useQuery<ExtractFnReturnType<typeof knotsApiSdk.getUserData>>({
    queryKey: ['userData'],
    queryFn: knotsApiSdk.getUserData,
    ...config,
  })
}
