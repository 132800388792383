import { useQuery } from '@tanstack/react-query'

import { queryClient } from '@/lib/react-query'

const storagePrefix = 'knots'
const defaultTokenLifetimeMs = 24 * 3600 * 1000

type tokenType = false | string
const getToken = (): tokenType => {
  let result: tokenType = false
  try {
    const storedToken = JSON.parse(window.localStorage.getItem(`${storagePrefix}token`) as string)
    if (storedToken.expires === null || storedToken.expires >= Date.now()) {
      result = storedToken.token
    } else {
      console.log('Clearing token', storedToken, Date.now())
      storage.clearToken()
    }
  } catch (e) {
    console.log('No valid authentication token found')
  }

  return result
}

const storage = {
  clearToken: () => {
    queryClient.setQueryData<tokenType>(['apiToken'], () => {
      try {
        window.localStorage.removeItem(`${storagePrefix}token`)
      } catch (error) {
        console.log('Could not remove token from localStorgage')
        console.error(error)
      }

      return false
    })
  },
  getToken,
  useToken: () => {
    return useQuery<tokenType>({
      queryKey: ['apiToken'],
      queryFn: async () => getToken(),
    })
  },
  setToken: (token: string, stayLoggedIn = false) => {
    queryClient.setQueryData<tokenType>(['apiToken'], () => {
      try {
        window.localStorage.setItem(
          `${storagePrefix}token`,
          JSON.stringify({
            expires: stayLoggedIn ? null : Date.now() + defaultTokenLifetimeMs,
            token: token,
          })
        )
      } catch (error) {
        console.log('Could not save token to localStorgage')
        console.error(error)
      }

      return token
    })
  },
}

export default storage
