import { Loader, i18n, supportedLanguages } from '@knots/shared-frontend'
import * as React from 'react'
import { Col, Row, Container } from 'react-bootstrap'

import { Button } from '@/components/Elements'
import { Footer } from '@/components/Footer'
import { Head } from '@/components/Head'
import { useAuth } from '@/providers/auth'

type AuthLayoutProps = {
  title: string
  left: React.ReactElement
  right: React.ReactElement
}

export const AuthLayout = ({ title, left, right }: AuthLayoutProps) => {
  const auth = useAuth()
  if (!auth.initialized) {
    return <Loader />
  }

  return (
    <>
      <Head title={title} />
      <Container fluid className="auth-layout">
        <Row>
          <Col
            className="auth-layout__left px-sm-3 px-lg-4 pb-3 pt-5 d-flex flex-column justify-content-center align-items-center position-relative"
            sm={12}
            md={6}
          >
            <Button
              className="auth-layout__language-switch position-absolute top-0 end-0 me-3"
              onClick={(e) => {
                e.preventDefault()
                i18n.changeLanguage(i18n.language === 'en' ? 'de' : 'en')
              }}
              variant="link"
            >
              {supportedLanguages.map((lang, index) => {
                if (lang !== i18n.language)
                  return (
                    <React.Fragment key={'locale_' + lang}>
                      {index === 0 ? '' : ' / '}
                      {lang}
                    </React.Fragment>
                  )
                else
                  return (
                    <React.Fragment key={'locale_' + lang}>
                      {index === 0 ? '' : ' / '}
                      <strong>{lang}</strong>
                    </React.Fragment>
                  )
              })}
            </Button>
            <Container className="px-sm-3 px-lg-5 mt-auto">{left}</Container>
            <Footer />
          </Col>
          <Col
            className="auth-layout__right px-sm-3 px-lg-4 py-3 d-flex justify-content-center align-items-center"
            sm={12}
            md={6}
          >
            <Container className="px-sm-3 px-lg-5">{right}</Container>
          </Col>
        </Row>
      </Container>
    </>
  )
}
