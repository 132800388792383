import { Nav, NavLinkProps as BsNavLinkProps } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'

import { usePreserveSearch } from '@/hooks/usePreserveSearch'

export type NavLinkProps = BsNavLinkProps & { href: string }

export const NavLink = ({ children, ...props }: NavLinkProps) => {
  const preserveSearch = usePreserveSearch()

  return (
    <LinkContainer to={preserveSearch(props.href, ['activation_redirect', /^utm_.+/])}>
      <Nav.Link {...props}>{children}</Nav.Link>
    </LinkContainer>
  )
}
