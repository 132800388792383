import React from 'react'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'

import { useAuth } from '@/providers/auth'

import { ForgotPassword } from './ForgotPassword'
import { Login } from './Login'
import { Register } from './Register'
import { ResetPassword } from './ResetPassword'

export const AuthRoutes = () => {
  const navigate = useNavigate()
  const { user, initialized: authInitialized } = useAuth()
  const location = useLocation()

  React.useEffect(() => {
    if (user) {
      const urlParams = new URLSearchParams(location.search)
      const redirectUrl = urlParams.get('login_redirect')

      if (redirectUrl) {
        const theUrl = decodeURIComponent(redirectUrl)
        // We want to be able to show a register page to someone already logged in
        if (location.pathname !== '/auth/register') {
          if (redirectUrl.startsWith('http')) {
            window.location.href = theUrl
          } else {
            navigate(theUrl)
          }
        }
      } else {
        // Don't auto redirect logged in users for password reset pages
        if (!['/auth/forgot-password', '/auth/reset-password'].includes(location.pathname)) {
          navigate('/')
        }
      }
    }
  }, [authInitialized, user, navigate, location.search, location.pathname])

  return (
    <Routes>
      <Route path="login" element={<Login />} />
      <Route path="register" element={<Register />} />
      <Route path="forgot-password" element={<ForgotPassword />} />
      <Route path="reset-password" element={<ResetPassword />} />
    </Routes>
  )
}
