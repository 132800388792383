import { i18n, supportedLanguages } from '@knots/shared-frontend'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import React from 'react'
import { Container, Nav, Navbar, Dropdown, Spinner } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import { ReactComponent as LogoFull } from '@/assets/logos/knots-full-neg.svg'
import { ReactComponent as LogoIcon } from '@/assets/logos/knots-icon-neg.svg'
import profilePicturePlaceholder from '@/assets/profile-picture-placeholder.svg'
import { NavLink } from '@/components/Elements'
import { usePreserveSearch } from '@/hooks/usePreserveSearch'
import { knotsApiSdk } from '@/lib/fetcher'
import { useAuth, useUserCurrency } from '@/providers/auth'
import { MemberRoutes, ValidMemberRoute } from '@/routes/protected'
import { showError } from '@/utils/error'
import './MyNavbar.scss'

const navData: Array<{
  title: string
  isDropdown: boolean
  route?: ValidMemberRoute
  routes?: ValidMemberRoute[]
}> = []
const navCategories: Record<string, (typeof navData)[number]> = {}

;(Object.keys(MemberRoutes) as ValidMemberRoute[]).forEach((route: ValidMemberRoute) => {
  const entry = MemberRoutes[route]

  if (entry.hideInNav) return

  if (entry.navCategory) {
    if (navCategories[entry.navCategory]?.routes) {
      navCategories[entry.navCategory]?.routes?.push(route)
    } else {
      navData.push({
        title: entry.navTitle,
        isDropdown: true,
        routes: [route],
      })
      navCategories[entry.navCategory] = navData[navData.length - 1]
    }
  } else {
    navData.push({
      title: entry.navTitle,
      route: route,
      isDropdown: false,
    })
  }
})

export const MyNavbar = () => {
  const currentRoute = useLocation().pathname as ValidMemberRoute
  const profilePicture = profilePicturePlaceholder
  const { t } = useTranslation()
  const auth = useAuth()
  const userCurrency = useUserCurrency()
  const [activeKey, setActiveKey] = React.useState<string>(currentRoute)
  const queryClient = useQueryClient()
  const preserveSearch = usePreserveSearch()
  const navigate = useNavigate()
  const showCurrencyPicker = !auth.user?.team.firstModuleActivatedAt

  const currencyMutation = useMutation(
    async (currency: string) => {
      await knotsApiSdk.setTeamCurrency(currency)
      await queryClient.invalidateQueries(['userData'])
    },
    {
      onError: showError,
    }
  )

  React.useEffect(() => {
    const newKey = navData.find((n) => n.routes?.includes(currentRoute))?.title ?? currentRoute
    setActiveKey(newKey)
  }, [currentRoute])

  return (
    <>
      <Navbar className="main-nav" variant="dark" bg="dark" expand="md" collapseOnSelect>
        <Container className="main-nav__container" fluid="lg">
          <Navbar.Brand href="/" className="order-0">
            <LogoIcon className="d-lg-none main-nav__logo main-nav__logo--icon" />
            <LogoFull className="d-none d-lg-block main-nav__logo main-nav__logo--full" />
          </Navbar.Brand>
          {auth.user ? (
            <>
              <Navbar.Toggle aria-controls="my-navbar-nav" className="order-4" />
              <Navbar.Collapse id="my-navbar-nav" className="justify-content-center order-4 order-md-1">
                <Nav
                  className="main-nav__navigation"
                  activeKey={activeKey}
                  onSelect={(k) => setActiveKey(k ?? currentRoute)}
                >
                  {navData.map((value, index) => {
                    if (value.isDropdown && value.routes) {
                      return (
                        <NavLink key={'main-nav-link-' + index} href={value.routes[0]} eventKey={value.title}>
                          {t(value.title)}
                        </NavLink>
                      )
                    } else if (value.route) {
                      return (
                        <NavLink key={'main-nav-link-' + index} href={value.route} eventKey={value.title}>
                          {t(value.title)}
                        </NavLink>
                      )
                    }
                  })}
                  <Nav.Link
                    href={t('nav.faqUrl')}
                    target="_blank"
                    onClick={(e) => {
                      e.stopPropagation()
                    }}
                  >
                    {t('nav.faq')}
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
              {showCurrencyPicker && (
                <Nav className="justify-content-end order-3 flex-row">
                  <Dropdown className="main-nav__profile my-1 my-md-0">
                    <Dropdown.Toggle className="font-weight-regular">
                      {currencyMutation.isLoading ? <Spinner animation="border" size="sm" /> : <>{userCurrency}</>}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="position-absolute">
                      {['EUR', 'USD'].map((currency) => {
                        return (
                          <Dropdown.Item
                            key={`currencySelect${currency}`}
                            onClick={async (e) => {
                              e.preventDefault()
                              if (userCurrency !== currency) {
                                currencyMutation.mutate(currency)
                              }
                            }}
                          >
                            {currency}
                          </Dropdown.Item>
                        )
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                </Nav>
              )}
              <Nav className="justify-content-end order-3 flex-row">
                <Dropdown className="main-nav__profile my-1 my-md-0">
                  <Dropdown.Toggle>
                    <div className="main-nav__profile-toggle d-inline-block">
                      <img src={profilePicture} alt="User profile" />
                    </div>
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="position-absolute">
                    <Dropdown.Item
                      onClick={(e) => {
                        e.preventDefault()
                        navigate(preserveSearch('/settings/profile', ['activation_redirect', /^utm_.+/]))
                      }}
                    >
                      {t('nav.profile')}
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={(e) => {
                        e.preventDefault()
                        navigate(preserveSearch('/settings/team', ['activation_redirect', /^utm_.+/]))
                      }}
                    >
                      {t('nav.team')}
                    </Dropdown.Item>
                    <Dropdown.Item href={t('footer.text.dpaUrl')} target="_blank">
                      {t('nav.dropdown.dpa')}
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={(e) => {
                        e.preventDefault()
                        auth.logout()
                      }}
                    >
                      {t('nav.dropdown.logout')}
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={(e) => {
                        e.preventDefault()
                        i18n.changeLanguage(i18n.language === 'en' ? 'de' : 'en')
                      }}
                    >
                      {supportedLanguages.map((lang, index) => {
                        if (lang !== i18n.language)
                          return (
                            <React.Fragment key={'locale_' + lang}>
                              {index === 0 ? '' : ' / '}
                              {lang}
                            </React.Fragment>
                          )
                        else
                          return (
                            <React.Fragment key={'locale_' + lang}>
                              {index === 0 ? '' : ' / '}
                              <strong>{lang}</strong>
                            </React.Fragment>
                          )
                      })}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Nav>
            </>
          ) : (
            <Nav.Link
              onClick={(e) => {
                e.preventDefault()
                i18n.changeLanguage(i18n.language === 'en' ? 'de' : 'en')
              }}
            >
              {supportedLanguages.map((lang, index) => {
                if (lang !== i18n.language)
                  return (
                    <React.Fragment key={'locale_' + lang}>
                      {index === 0 ? '' : ' / '}
                      {lang}
                    </React.Fragment>
                  )
                else
                  return (
                    <React.Fragment key={'locale_' + lang}>
                      {index === 0 ? '' : ' / '}
                      <strong>{lang}</strong>
                    </React.Fragment>
                  )
              })}
            </Nav.Link>
          )}
        </Container>
      </Navbar>
    </>
  )
}
