import { useQuery } from '@tanstack/react-query'

import { knotsApiSdk } from '@/lib/fetcher'
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query'

type QueryFnType = typeof knotsApiSdk.getTeamInviteDetails

type UseTeamInviteDetailsOptions = {
  inviteToken?: string
  config?: QueryConfig<QueryFnType>
}

export const useTeamInviteDetails = ({ inviteToken, config }: UseTeamInviteDetailsOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    queryKey: ['teamInvites', inviteToken],
    queryFn: async () => await knotsApiSdk.getTeamInviteDetails({ token: inviteToken as string }),
    enabled: typeof inviteToken === 'string' && inviteToken.length === 64,
    useErrorBoundary: false,
    ...config,
  })
}
