import React, { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'

import { useUserData } from '@/features/auth'
import type { AuthRegisterRequest, AuthRegisterResponse, User } from '@/features/auth'
import { knotsApiSdk, knotsCFApiSdk } from '@/lib/fetcher'
import { toastError } from '@/lib/toast'
import storage from '@/utils/storage'

export type AuthProviderValues = {
  initialized: boolean
  user: null | User
  login: (accessToken: string, stayLoggedIn: boolean) => void
  logout: () => Promise<void>
  register: (_: AuthRegisterRequest) => Promise<AuthRegisterResponse>
  changePassword: (password: string, newPassword: string) => Promise<void>
}

const defaultValues: AuthProviderValues = {
  initialized: false,
  user: null,
  login: () => {},
  logout: async () => {},
  register: async () => ({}),
  changePassword: async () => {},
}

export const AuthContext = React.createContext(defaultValues)
export const AuthProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [isSSO, setIsSSO] = React.useState(false)

  const { t } = useTranslation()
  const tokenQuery = storage.useToken()
  const userDataQuery = useUserData({
    config: {
      enabled: !!tokenQuery?.data,
      onError: (e) => {
        console.log('Failed to retried user data', e)
        toastError(t('message.user.unexpectedEvent'))
      },
    },
  })

  // Check if SSO token present and login if needed
  React.useEffect(() => {
    const getRealToken = async () => {
      const urlSearchParams = new URLSearchParams(window.location.search)
      const params = Object.fromEntries(urlSearchParams.entries())
      if (params?.sso) {
        setIsSSO(true)
        storage.setToken(params?.sso, false)
        const ssoRes = await knotsApiSdk.loginWithSSOToken()
        if (ssoRes && ssoRes.access_token) {
          storage.setToken(ssoRes.access_token, true)
        }
        setIsSSO(false)
      }
    }

    getRealToken()
  }, [])

  const login = async (accessToken: string, stayLoggedIn = false) => {
    if (accessToken) {
      storage.setToken(accessToken, stayLoggedIn)
    }
  }

  const register = async (data: AuthRegisterRequest) => {
    return await knotsCFApiSdk.registerUser(data)
  }

  const logout = async () => {
    storage.clearToken()
    window.location.assign(window.location.origin as unknown as string)
  }

  const changePassword = async (currentPassword: string, newPassword: string) => {
    await knotsApiSdk.changePassword({ currentPassword, newPassword })
  }

  const state = {
    user: userDataQuery.data ?? null,
    initialized: (userDataQuery.isSuccess || (tokenQuery.isSuccess && userDataQuery.fetchStatus === 'idle')) && !isSSO,
    login,
    logout,
    register,
    changePassword,
  }

  return <AuthContext.Provider value={state}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
  return React.useContext(AuthContext)
}

export const useUserCurrency = () => {
  const { user } = useAuth()
  return user?.team?.currency ?? 'EUR'
}
