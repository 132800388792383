import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { Button } from '@/components/Elements'

export const NoAccount = () => {
  const { t } = useTranslation()
  const location = useLocation()

  return (
    <>
      <div className="text-center">
        <h2>{t('auth.noaccount.title')}</h2>
        <div>{t('auth.noaccount.text.noAccountYet')}</div>
        <Button className="w-100" href={`/auth/register${location.search?.length ? location.search : ''}`}>
          {t('auth.noaccount.button.register')}
        </Button>
      </div>
    </>
  )
}
