import { To, useLocation } from 'react-router-dom'

export const usePreserveSearch = () => {
  const location = useLocation()

  const preserveSearch = (
    url: string | To,
    searchParams: Array<string | RegExp>
  ): string | { hash?: string; pathname: string; search?: string } => {
    // Reassembles "To" into a string so we have a single code path, malformed "To" could have a query in the pathname
    let urlString
    if (typeof url !== 'string') urlString = `${url?.pathname ?? ''}?${url?.search ?? ''}#${url?.hash ?? ''}`
    else urlString = url

    // Only makes sense for internal use, skip if it's a full url
    if (urlString.startsWith('http')) return url as string

    const hashSplit = urlString.split('#')
    let hash = undefined
    if (hashSplit.length > 1) hash = hashSplit.pop()

    const searchSplit = hashSplit[0].split('?')

    const search = new URLSearchParams(location.search)
    const preserved = new URLSearchParams(searchSplit.length > 1 ? searchSplit.pop() : undefined)
    for (const [key, value] of Array.from(search.entries())) {
      searchParams.forEach((param) => {
        if (typeof param === 'string' && param === 'key') preserved.set(key, value)
        else if (param instanceof RegExp && param.test(key)) preserved.set(key, value)
      })
    }

    return {
      hash,
      pathname: searchSplit[0],
      search: preserved.size > 0 ? preserved.toString() : undefined,
    }
  }

  return preserveSearch
}
